import { useState, createContext, useContext, useRef } from "react";
import { useCallback } from "react";
import io, { Socket } from "socket.io-client";
import { DefaultEventsMap } from "socket.io/dist/typed-events";
import { SocketError } from "@multiplayer/types";

const baseURL = process.env.REACT_APP_API_BASE_URL || "";
const collaborationPrefix = process.env.REACT_APP_COLLABORATION_PREFIX;

export interface ISocketContext {
  connected: boolean;

  subscribe: (event: string, cb: (...args: any) => void) => void;
  unsubscribe: (event: string, cb: (...args: any) => void) => void;
  emitEvent: (event: string, ...args: any) => void;
  connect: (arg: string) => Socket<DefaultEventsMap, DefaultEventsMap>;
  disconnect: () => void;
}

const SocketContext = createContext<ISocketContext>(null);

const SocketProvider = ({ children }: { children: React.ReactNode }) => {
  const socketRef = useRef<Socket<DefaultEventsMap, DefaultEventsMap>>(null);
  const [connected, setConnected] = useState(false);
  const emitters = useRef({});

  const connect = useCallback((projectId: string) => {
    socketRef.current = io(`${baseURL}/project|${projectId}`, {
      path: `${collaborationPrefix}/ws`,
      withCredentials: true,
      transports: ["websocket", "polling"],
    });

    const onConnect = () => {
      setConnected(true);
    };

    const onDisconnect = () => {
      setConnected(false);
    };

    const onReconnect = () => {
      setConnected(true);
      Object.keys(emitters.current).forEach((event) => {
        socketRef.current.emit(event, ...emitters.current[event]);
      });
    };

    const onError = (error: SocketError) => {
      console.info("Socket error!", error);
    };

    socketRef.current.on("connect", onConnect);
    socketRef.current.on("reconnect", onReconnect);
    socketRef.current.on("connect_error", onError);
    socketRef.current.on("disconnect", onDisconnect);

    return socketRef.current;
  }, []);

  const disconnect = useCallback(() => {
    setConnected(false);
    if (!socketRef.current) return;
    socketRef.current.off("disconnect");
    socketRef.current.off("connect");
    socketRef.current.off("reconnect");
    socketRef.current.disconnect();
  }, []);

  const subscribe = useCallback((event: string, cb: (arg: any) => void) => {
    socketRef.current.on(event, cb);
  }, []);

  const unsubscribe = useCallback((event: string, cb: (arg: any) => void) => {
    socketRef.current?.off(event, cb);
  }, []);

  const emitEvent = useCallback((event: string, ...args: any) => {
    emitters.current[event] = { ...args };
    socketRef.current?.emit(event, ...args);
  }, []);

  return (
    <SocketContext.Provider
      value={{
        connected,
        connect,
        subscribe,
        emitEvent,
        disconnect,
        unsubscribe,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};

function useSocket() {
  const context = useContext(SocketContext);
  if (context === null) {
    throw new Error("useSocket must be used within SocketProvider");
  }
  return context;
}

export { useSocket, SocketProvider };
