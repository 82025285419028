(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@opentelemetry/resources"), require("@opentelemetry/core"), require("@opentelemetry/sdk-trace-web"), require("@opentelemetry/semantic-conventions"), require("@opentelemetry/instrumentation"), require("@opentelemetry/auto-instrumentations-web"), require("socket.io-client"));
	else if(typeof define === 'function' && define.amd)
		define(["@opentelemetry/resources", "@opentelemetry/core", "@opentelemetry/sdk-trace-web", "@opentelemetry/semantic-conventions", "@opentelemetry/instrumentation", "@opentelemetry/auto-instrumentations-web", "socket.io-client"], factory);
	else if(typeof exports === 'object')
		exports["MultiplayerSessionDebugger"] = factory(require("@opentelemetry/resources"), require("@opentelemetry/core"), require("@opentelemetry/sdk-trace-web"), require("@opentelemetry/semantic-conventions"), require("@opentelemetry/instrumentation"), require("@opentelemetry/auto-instrumentations-web"), require("socket.io-client"));
	else
		root["MultiplayerSessionDebugger"] = factory(root["@opentelemetry/resources"], root["@opentelemetry/core"], root["@opentelemetry/sdk-trace-web"], root["@opentelemetry/semantic-conventions"], root["@opentelemetry/instrumentation"], root["@opentelemetry/auto-instrumentations-web"], root["socket.io-client"]);
})(this, (__WEBPACK_EXTERNAL_MODULE__opentelemetry_resources__, __WEBPACK_EXTERNAL_MODULE__opentelemetry_core__, __WEBPACK_EXTERNAL_MODULE__opentelemetry_sdk_trace_web__, __WEBPACK_EXTERNAL_MODULE__opentelemetry_semantic_conventions__, __WEBPACK_EXTERNAL_MODULE__opentelemetry_instrumentation__, __WEBPACK_EXTERNAL_MODULE__opentelemetry_auto_instrumentations_web__, __WEBPACK_EXTERNAL_MODULE_socket_io_client__) => {
return 